import styled from '@emotion/styled';
import {GatsbyImage} from 'gatsby-plugin-image';
import Slider from 'react-slick';

export const MainSlider = styled(Slider)`
  width: 100%;
  margin: 0 0 10px;
  background-color: #f5f5f5;
  cursor: pointer;

  .slick-track {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }
`;

export const MainSlide = styled.div`
  background-color: #f2f2f2;
  text-align: center;
`;

export const NavSlider = styled(Slider)`
  width: 100%;
  overflow: hidden;

  .slick-list {
    margin: 0 -5px;
  }

  .slick-track {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
  }

  .slick-slide {
    height: auto;

    div {
      height: 100%;
    }
  }

  .gatsby-image-wrapper {
    border: 1px solid #f2f2f2;
    opacity: 1;
    transition: opacity 0.3s;
  }

  .slick-current {
    .gatsby-image-wrapper {
      opacity: 0.75;
    }
  }

  &.hide-cloned {
    .slick-cloned {
      display: none;
    }
  }
`;

export const NavSlide = styled.div`
  display: block !important;
  padding: 0 5px;
`;

export const Image = styled(GatsbyImage)`
  background-color: #f5f5f5;
  transition: background-color 0.3s;

  img {
    margin: 0;
  }
`;
