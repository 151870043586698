// extracted by mini-css-extract-plugin
export var otherCategories = "product-page-module--other-categories--11b62";
export var otherCategories__slide = "product-page-module--other-categories__slide--50bfe";
export var otherCategories__slider = "product-page-module--other-categories__slider--49527";
export var otherProducts = "product-page-module--other-products--692c2";
export var otherProducts__slide = "product-page-module--other-products__slide--a2f33";
export var otherProducts__slider = "product-page-module--other-products__slider--57ffe";
export var productDescription = "product-page-module--product-description--0490c";
export var productDescription__btnGroup = "product-page-module--product-description__btn-group--0555e";
export var productDescription__container = "product-page-module--product-description__container--30cc1";
export var productDescription__content = "product-page-module--product-description__content--01ef1";
export var productDescription__slider = "product-page-module--product-description__slider--8b656";