import React from 'react';
import { Form, FormItem } from './OfferForm.styled';
import Button from '../Button';

const OfferForm = ({ productCode, setModalVisibility, setNotificationVisible, setNotificationContent }) => {
  const [sendingMail, setSendingMail] = React.useState(false);
  const showNotification = isError => {
    setNotificationVisible(true);
    setNotificationContent({
      error: isError,
      message: isError ?
        "Nie udało się wysłać wiadomości. Spróbuj ponownie później lub skontaktuj się z nami telefonicznie." :
        "Wiadomość została wysłana. Wkrótce się z Tobą skontaktujemy."
    });
  };

  const clearForm = elements => elements.forEach(element => element.value = null);

  const sendMail = event => {
    event.preventDefault();
    const { productCode, name, mail, message } = event.target.elements;
    setSendingMail(true);
    fetch('https://admin-expozytor.codemag.pl/api/forms/submit/offerForm?token=33416722cb3983b7bf25a03ee42dfa', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "form": {
          "Produkt": productCode.value,
          "Imię i Nazwisko": name.value,
          "Email": mail.value,
          "Wiadomość": message.value
        }
      })
    }).then(entry => entry.json()).then(entry => {
      showNotification(entry.error ? true : false);
      !entry.error && clearForm([productCode, name, mail, message]);
      setSendingMail(false);
      !entry.error && setModalVisibility(false);
    }).catch(() => {
      showNotification(true);
      setSendingMail(false);
    });
  };

  return (
    <Form onSubmit={sendMail}>
      <FormItem>
        <input type="text" name="productCode" value={productCode} placeholder="Kod produktu" disabled required/>
      </FormItem>
      <FormItem>
        <input type="text" name="name" placeholder="Imię i Nazwisko" required />
      </FormItem>
      <FormItem>
        <input type="email" name="mail" placeholder="Email" required/>
      </FormItem>
      <FormItem>
        <textarea name="message" cols="30" rows="5" placeholder="Wiadomość" required/>
      </FormItem>
      <Button type="submit" variant='primary' color='white' disabled={sendingMail}>
        {sendingMail ? 'Wysyłanie...' : 'Wyślij'}
      </Button>
    </Form>
  );
};

export default OfferForm;
