import React from 'react';
import { graphql } from 'gatsby';
import slugify from 'slugify';
import Slider from 'react-slick';
import Seo from '../components/SEO';
import Layout from '../components/Layout';
import Breadcrumbs from '../components/Breadcrumbs';
import ProductSlider from '../components/ProductSlider';
import Product from '../components/Product';
import Modal from '../components/Modal';
import Button from '../components/Button';
import OfferForm from '../components/OfferForm/OfferForm';
import Notification from '../components/Notification';
import * as style from '../assets/style/pages/product-page.module.scss';

const ProductPage = ({ location, data }) => {
  const offers = data.offers.edges;
  const product = data.product.edges.map(({ node }) => node)[0];
  const firstOfferSlug = slugify(offers[0].node.title.value, {lower: true});
  const categorySlug = slugify(product.category.value.title.value, { lower: true });
  const productSlug = slugify(product.title.value, { lower: true });

  const crumbs = [
    { title: 'Strona główna', url: '/' },
    { title: 'Oferta', url: `/oferta/${firstOfferSlug}` },
    { title: product.category.value.title.value, url: `/oferta/${categorySlug}` },
    { title: 'Katalog', url: `/oferta/${categorySlug}/katalog` },
    { title: product.title.value, url: `/oferta/${categorySlug}/katalog/${productSlug}` }
  ];

  const sliderCfg = {
    autoplay: true,
    arrows: false,
    pauseOnHover: false,
    pauseOnFocus: false,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }

  const [modalVisibility, setModalVisibility] = React.useState('unvisible');
  const [notificationVisible, setNotificationVisible] = React.useState(false);
  const [notificationContent, setNotificationContent] = React.useState({
    error: false,
    message: ""
  });
  const [sliderVisible, setSliderVisible] = React.useState(true);

  React.useEffect(() => {
    const onWindowResize = () => {
      const productsLength = data.products.edges.length;
      const windowWidth = window.outerWidth;
      if (
        (windowWidth < 576 && productsLength <= 1) ||
        (windowWidth >= 576 && windowWidth < 768 && productsLength <= 2) ||
        (windowWidth >= 768 && windowWidth < 1200 && productsLength <= 3) ||
        (windowWidth >= 1200 && productsLength <= 4)
      ) {
        setSliderVisible(false);
      } else {
        setSliderVisible(true);
      }
    };

    onWindowResize();
    window.addEventListener('resize', onWindowResize);

    return () => window.removeEventListener('resize', onWindowResize)
  });

  const showModal = () => {
    setModalVisibility('visible');
  };

  return (
    <Layout>
      <Seo title={product.title.value}>
        <script type="application/ld+json">
          {`{
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement":
            [
              ${crumbs.map(({ title, url }, i) => `
                {
                  "@type": "ListItem",
                  "position": ${i + 1},
                  "item": {
                    "@type": "WebPage",
                    "@id": "${location.origin + url}",
                    "name": "${title}"
                  }
                }
              `)}
            ]
          }`}
        </script>
      </Seo>
      <Breadcrumbs crumbs={crumbs} />
      <section className={`section ${style.productDescription}`}>
        <div className={`container ${style.productDescription__container}`}>
          <h1>{product.title.value}</h1>
          <div className={style.productDescription__slider}>
            <ProductSlider images={product.gallery.value} title={product.title.value} />
          </div>
          <div className={style.productDescription__content}>
            {product.description?.value ? <>
              <strong>Opis:</strong>
              <div dangerouslySetInnerHTML={{ __html: product.description.value }}></div>
            </> : null}
            {product.specification.value[0].value.name ? <>
              <strong>Specyfikacja:</strong>
              <table>
                <tbody>
                  {product.specification.value.map(({ value }, i) => (
                    <tr key={i}>
                      <td><strong>{value.name.value}</strong></td>
                      <td>{value.value.value}</td>
                    </tr>))
                  }
                </tbody>
              </table>
            </> : null}
            <div className={style.productDescription__btnGroup}>
              <Button variant='primary' color='white' onClick={showModal}>
                Zapytaj o produkt
              </Button>
            </div>
          </div>
        </div>
      </section>
      <section className={`section ${style.otherProducts}`}>
        <div className="container">
          <h2 className="section__title section__title--center">
            <span>Inne produkty</span>
            Pozostałe z tej kategorii
          </h2>
          {sliderVisible ? <Slider className={style.otherProducts__slider} {...sliderCfg}>
            {data.products.edges.map(({ node }) => (
              <div className={style.otherProducts__slide} key={node.id}>
                <Product product={node} url={`/oferta/${categorySlug}/katalog/${slugify(node.title.value, { lower: true })}`} />
              </div>
            ))}
          </Slider> :
          <div className="row">
            {data.products.edges.map(({ node }) => (
              <div className="col-12 col-sm-6 col-md-4 col-xl-3" key={node.id}>
                <Product product={node} url={`/oferta/${categorySlug}/katalog/${slugify(node.title.value, { lower: true })}`} />
              </div>
            ))}
          </div>}
        </div>
      </section>
      <section className={`section ${style.otherCategories}`}>
        <div className="container">
          <h2 className="section__title section__title--center">
            <span>Inne kategorie</span>
            Co jeszcze produkujemy
          </h2>
          <Slider className={style.otherCategories__slider} {...sliderCfg}>
            {data.offers.edges.map(({ node }) => (
              <div className={style.otherCategories__slide} key={node.id}>
                <Product product={node} url={`/oferta/${slugify(node.title.value, { lower: true })}`} />
              </div>
            ))}
          </Slider>
        </div>
      </section>
      <Modal visibility={modalVisibility} setVisibility={setModalVisibility}>
        <OfferForm productCode={product.title.value} setModalVisibility={setModalVisibility} setNotificationVisible={setNotificationVisible} setNotificationContent={setNotificationContent} />
      </Modal>
      <Notification open={notificationVisible} setOpen={setNotificationVisible} error={notificationContent.error}>
        {notificationContent.message}
      </Notification>
    </Layout>
  );
};

export const query = graphql`
  query($id: String, $category: String) {
    product: allCockpitProducts(filter: {id: {eq: $id}}) {
      edges {
        node {
          title {
            value
          }
          description {
            value
          }
          specification {
            value {
              value {
                name {
                  value
                }
                value {
                  value
                }
              }
            }
          }
          gallery {
            value {
              childImageSharp {
                id
                gatsbyImageData(
                  quality: 100,
                  placeholder: BLURRED,
                  formats: WEBP
                )
              }
            }
          }
          category {
            value {
              title {
                value
              }
            }
          }
        }
      }
    }
    products: allCockpitProducts(filter: {category: {value: {id: {eq: $category}}}}) {
      edges {
        node {
          id
          image {
            value {
              childImageSharp {
                gatsbyImageData(
                  width: 360,
                  height: 360,
                  quality: 100,
                  placeholder: BLURRED,
                  formats: WEBP
                )
              }
            }
          }
          title {
            value
          }
        }
      }
    }
    offers: allCockpitOffer {
      edges {
        node {
          id
          image {
            value {
              childImageSharp {
                gatsbyImageData(
                  width: 360,
                  height: 360,
                  quality: 100,
                  placeholder: BLURRED,
                  formats: WEBP
                )
              }
            }
          }
          title {
            value
          }
          description: excerpt {
            value
          }
        }
      }
    }
  }
`;

export default ProductPage;
